input#dob {
    width: 100%;
    height: 50px;
    padding: 10px;
    font-size: 15px;
    border-radius: 0.35rem;
    color: gray;
    border: 1px solid #afafaf;
    text-transform: uppercase;
}
.createBtn_wapper{
    margin-bottom: 10px;
}
.haveAccount{
    color: var(--primary-color4);
    font-size: 15px;
    font-weight: 300;
}
.haveAccount a{
    color: var(--primary-color3);
    margin-left: 5px;
}