.loginSingupWapper{
    background: url(../../public/images/signup.png)center no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}
.loginSingupWapper img{
    filter: drop-shadow(1px 5px 4px gray);
}
.full-height{
    min-height: 100vh;
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}
.signup {
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
   
}
.signup h3 {
    font-size: 20px;
    line-height: 1;
    color: var(--primary-color3);
    margin-bottom: 20px;
}
.signup h3 strong{
    padding-bottom: 17px;
    font-size: 32px !important;
    color: var(--header-link);
}
svg#facebook,svg#apple{
    height: 30px;
    width: 30px;
}
svg#facebook{
    height: 20px;
}
svg#google {
    width: 19px;
    margin-right: 8px;
}
.fb-btn{
    background: #3e8af4;
    color: #fff;
    fill: #fff;
}
.apple-btn{
    color: #fff;
    fill: #fff;
    background-color: #000;
}
.google-btn{
    background: #fff;
    color: #000;
}
.signUpButton button {
    border: none;
    padding: 5px 10px;
    border-radius: 10px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 32%;
}
.signUpButton strong {
    font-size: 15px;
    display: block;
    font-weight: 500;
}
.signUpButton{
    display: flex;
    justify-content: center;
    gap: 10px;
}
.loginSingupWapper strong {
    font-size: 13px;
    display: block;
}
.or{
    position: relative;
    
}
.or span{
    background-color: var(--primary-color10);
    position: relative;
    z-index: 9;
    display: inline-block;
    padding: 0 10px;
    color: var(--primary-color1);
}
.or::before{
    position: absolute;
    top: 50%;
    left: 0;
    height: 2px;
    width: 100%;
    content: "";
    background-color: var(--primary-color3);z-index: 1;
}
.inp-wrapper > input{
    width: 100%;
    border: 1px solid #afafaf;
}
.img {
    width: 430px;
    margin: 0 auto;
    position: absolute;
    bottom: 540px;
    transform: rotate(-35deg);
}
.img .screw {
    position: absolute;
    width: 32px;
    top: 11px;
    right: 14px;
}
.inp-wrapper input:focus-visible,.inp-wrapper input:focus{
    outline: none;
}
.inp-wrapper input{
    font-size: 0.7rem;
    padding: 10px;
}

.error-msg p {
    font-size: 0.7rem;
    color: var(--primary-color8);
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 0;
}
.inp-wrapper{
    margin-bottom: 20px;
}
.trems_tags{
    padding-top: 10px;
}
input[type="checkbox" i] {
    appearance: none;
    position: relative;
}
input[type="checkbox" i]::before {
    appearance: none;
    content: "";
    width: 15px;
    height: 15px;
    border: 2px solid  var(--primary-color8);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 2px;
}
input[type="checkbox" i]::after{
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: var(--primary-color2);
    transform: translate(-50%,-50%);
    display: none;
    border-radius: 50%;
}

input[type="checkbox" i]:checked::after {
   display: block;
   /* background-color: var(--primary-color10); */
}
.tacbox label {
    font-size: 13px;
    text-align: left;
    line-height: 16px;
    padding-left: 17px;
    
}
.tacbox label a{
    color: var(--primary-color3) !important;
}
.btn-black{
    background: var(--primary-color2);
    width: 70%;
    color: #fff;
}
@media(min-width: 767px){
    .inp-wrapper input, .firstnamelastname input {
        padding: 15px;
    }
    .signUpButton strong {
        font-size: 18px;
    }
    .signUpButton button {
        height: 50px;
    }
    .overlay{
        padding: 20px 50px;
        /* background: rgba(2, 0, 22, 0.5); */
        border-radius: 60px;
    }
}

.password-container {
    position: relative; /* Enables positioning the icon inside the container */
    display: flex;
    align-items: center;
  }
  
  .form-control {
    width: 100%;
    padding-right: 40px; /* Create space for the icon */
    padding-left: 10px;
    box-sizing: border-box; /* Ensure padding doesn't affect width */
  }
  
  .password-toggle-icon {
    position: absolute;
    right: 10px; /* Place the icon inside the input at the end */
    cursor: pointer;
    color: #888; /* Icon color */
    z-index: 1; /* Ensure the icon is above the input */
  }
  
  .password-toggle-icon:hover {
    color: #000; /* Change icon color on hover */
  }
  



