.progress-container {
  text-align: center;
  margin-bottom: 30px;
}

#progressbar {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  color: lightgrey;
}

#progressbar li {
  flex: 1;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  position: relative;
}

#progressbar li.active {
  color: #2f8d46;
}

.progress {
  height: 20px;
  background-color: lightgray;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar {
  background-color: #2f8d46;
  width: 0;
  height: 100%;
  transition: width 0.4s ease-in-out;
}

.step-container fieldset {
  /* background: white; */
  /* border: 1px solid #ccc;
  border-radius: 5px; */
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative;
  display: none;
}

.step-container fieldset:first-of-type {
  display: block;
}

h2 {
  color: var(--primary-color2);
  margin-top: 10px;
  text-align: center;
}

.next-step,
.previous-step {
  width: 100px;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px 10px 0px;
  float: right;
}

.next-step {
  background: #2f8d46;
}

.previous-step {
  background: #616161;
}

.next-step:hover,
.next-step:focus {
  background-color: #1e6f3e;
}

.previous-step:hover,
.previous-step:focus {
  background-color: #4d4d4d;
}

.text {
  color: #2f8d46;
  font-weight: normal;
}

.finish {
  text-align: center;
}

.stepper-wrapper {
  /* margin-top: auto; */
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid var(--primary-color3);
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--primary-color3);
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: var(--primary-color2);
  color: #fff;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid var(--primary-color2);
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

.story-button {
  height: 55px;
  width: 200px;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 30px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  background: rgb(130, 106, 251);
  border-radius: 0 20px;
  border: 2px solid #265073 !important;
  background-color: #f1fada !important;
  color: #265073 !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  transition: all 1s ease;
}

.success_message{
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 1px;
}

.loader_wapper {
  height: calc(100vh - var(--header));
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.step-name {
  color: var(--primary-color1);
}
fieldset .createBtn{
  margin-top: 100px;
}
@media(min-width:767px){
  .success_message {
    font-size: 30px;
    line-height: 1.2;
}
}