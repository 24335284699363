.aboutustext {
    flex: 0 0 40%;
}
.aboutusImg {
    flex: 0 0 50%;
}
section#aboutUs {
    padding: 7.5rem 0;
    background: url(../../public/images/aboutusbg.png)center no-repeat;
    background-size: cover;
}
.aboutustext strong{
        font-size: 1rem;
        line-height: 1;
        color: var(--primary-color2);
}
.aboutustext h2 {
    font-size: 1.6rem;
    line-height: 1;
    color: var(--primary-color4);
    text-align: left;
    margin-bottom: 20px;
    font-weight: 700;
}
.aboutustext p {
    font-size: 16px;
    text-align: justify;
    font-family: var(--cabinFont);
}
.abuLink{
    color: var(--primary-color9);
    text-decoration: none;
}