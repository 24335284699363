@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

/* Responsive Css Code created by : Shift Code Design */


ul{
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}
 .footer p {
  font-size: 16px;
  line-height: 30px;
  color: #898b96;
  font-weight: 300;
}

.footer h4 {
  font-family: "Poppins", sans-serif;
  margin: 0;
  font-weight: 400;
  padding: 0;
  color: #363940;
}

.footer a {
  color: #5867dd;
}

.footer-big {
  padding: 105px 0 65px 0;
}

.footer-big .footer-widget {
  margin-bottom: 40px;
}

.footer--light {
  /* background: #001441; */
  background: var(--bg-gray-background) url(../../public/images/aboutusbg.png)center no-repeat;
  background-size: cover;
}

.footer-big .footer-menu ul li a,
.footer-big p,
.footer-big ul li {
  color: #898b96;
}

/* Responsive Css Code created by : Shift Code Design */

.footer-menu {
  padding-left: 48px;
}

.footer-menu ul li a {
  font-size: 15px;
  line-height: 32px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.footer-menu ul li a:hover {
  color: var(--primary-color2);
}

.footer-widget-title {
  line-height: 30px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
}

.mini-footer {
  background: var(--primary-color3);
  text-align: center;
  padding: 32px 0;
}

.mini-footer p {
  margin: 0;
  line-height: 26px;
  font-size: 15px;
  color: var(--primary-color4);
}

.mini-footer p a {
  color: var(--primary-color1);
  font-weight: 500;
  margin: 0 5px;
}
.mini-footer p a:hover {
  color: #34bfa3;
}

/* Responsive Css Code created by : Shift Code Design */

.widget-about img {
  display: block;
  /* margin-bottom: 30px; */
  max-width: 70%;
}

.widget-about p {
  font-weight: 400;
  font-size: 0.8rem;
}

.widget-about .contact-details {
  margin: 30px 0 0 0;
}

.widget-about .contact-details li {
  margin-bottom: 10px;
  font-size: 15px;
}

.widget-about .contact-details li:last-child {
  margin-bottom: 0;
}

.widget-about .contact-details li i {
  padding-right: 10px;
  color: #333;
}

.widget-about .contact-details li a {
  color: var(--primary-color9);
}

/* Responsive Css Code created by : Shift Code Design */

@media (max-width: 991px) {
  .footer-menu {
    padding-left: 0;
  }
  .widget-about img {
    max-width: 50%;
  }
}
a{
  text-decoration: none !important;
}