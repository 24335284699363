
.title h4{
  font-weight: 600;
  margin-top: 20px;
  font-size: 44px;
  color: var(--primary-color3);
}
.title p {
  font-size: 15px;
  width: 60%;
  margin: 0 auto;
  color: var(--header-link);
}

.opt form input {
  display: inline-block;
  width: 200px;
  height: 53px;
  text-align: center;
  border: none;
  border-bottom: 3px solid var(--primary-color2);
  margin: 0 10px;
  background: transparent;
  font-size: 40px;
  line-height: 40px;
  color: var(--paragraphFontcolor);
}
.opt form input:focus{
  box-shadow: none;
  outline: none;
}
.opt{
  background: url(../../public/images/signup.png)center no-repeat;
  background-size: cover;
  min-height: 100vh;
  /* height: 100%; */
}
#OTP-wapper{
  min-height: 100vh;
}
.opt .createBtn{
  width: 40%;
}
.opt .createBtn span{
 font-size: 20px !important;
}

.resend {
  font-size: 15px;
  color: var(--header-link);
}

.trouble{
  font-size: 15px;
  line-height: 40px;
  color: var(--header-link);
}
.opt img{
  filter: drop-shadow(1px 5px 4px gray);
}