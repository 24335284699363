.chapter-content {
    font-size: 19px;
    font-style: italic;
    /* padding: 10px; */
    /* text-align: left; */
    line-height: 34px;
    text-align: justify;
    color: var(--primary-color4);
  
}
.chapter-content::first-letter {
    font-size: 2em; /* Adjust the size as needed */
    font-weight: bold; /* Optional: makes the first letter bold */;
    color: var(--primary-color2);
}
@media(min-width: 992px){
    .chapter-content {
        font-size: 16px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-thickness: 1px;
        text-underline-offset: 6px;
        text-decoration-color: #7a7b7c;
        text-decoration-skip-ink: none; /* works in Firefox & Chrome, not in Safari */
        text-decoration-skip: none; /* works in Safari, not in Firefox & Chrome */
    }
}
@media(min-width:1199px){
    .chapter-content{
        padding: 0 25px;
        font-size: 20px;
        line-height: 44px;
        color: var(--primary-color10);
    }
}