.login_section{
    min-height: calc(100vh);
}
.loginOuterDiv{
    background: url(..//..//public/images/backgroundmobile.jpg) top center no-repeat;
    background-size: cover;
min-height: 100vh;
padding: 50px 0;
}
.login_wapper{
    /* padding-top: 9rem; */
}
label {
    display: block;
    font-size: 20px;
    color: var(--primary-color4);
    line-height: 35px;
}
.input_wapper h4 {
    font-size: 2rem;
    text-align: center;
    color: var(--primary-color10);
    margin-bottom: 1.5rem;
    font-weight: bolder;
}
.input-box input {
    width: 100%;
    height: 50px;
    border: 1px solid var(--primary-color5);
    background: var(--primary-color2);
    border-radius: 10px;
    padding: 0 10px;
    font-size: 20px;
    color: var(--primary-color3);
}
.input-box {
    margin-bottom: 20px;
}
.input-box input:focus-visible{
    outline: var(--primary-color3);
}
.input-box input::placeholder{
    color: var(--primary-color4);
}
.input_wapper .createBtn{
    width: 100%;
}
