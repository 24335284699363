.exploreSectionHeadding {
    font-size: 1.175rem;
    text-align: center;
    font-weight: 600;
    color: var(--primary-color2);
    margin-bottom: 3.2rem;
}
.exploreSectionHeadding strong {
    display: block;
    font-size: var(--heddingFont);
    color: var(--primary-color8);
}
