@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
.music-container {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 20px 20px 0 rgba(252, 169, 169, 0.6);
    display: flex;
    padding: 20px 30px;
    position: relative;
    margin: 30px 0;
    z-index: 10;
  }
  
  .img-container {
    position: relative;
    width: 110px;
  }
  
  .img-container.play::after {
    content: '';
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    bottom: 100%;
    left: 42%;
    width: 20px;
    height: 20px;
    top: -34px;
    transform: translate(-50%, 50%);
  }
  
  .img-container img#cover {
    border-radius: 50% !important;
    object-fit: cover;
    height: 95px;
    width: 94px;
    position: absolute;
    bottom: -17px;
    left: 0;
    animation: rotate 3s linear infinite;
    opacity: 1 !important;
    animation-play-state: paused;
    transition: bottom 0.5s ease-in;
  }
  
  .music-container.play .img-container img#cover {
    animation-play-state: running;
    bottom: 34px;
    transition: bottom 0.5s ease-in;
  }

  
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  .navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  
  .action-btn {
    background-color: #fff;
    border: 0;
    color: #dfdbdf;
    font-size: 20px;
    cursor: pointer;
    padding: 10px;
    margin: 0 20px;
  }
  
  .action-btn.action-btn-big {
    color: #cdc2d0;
    font-size: 30px;
  }
  
  .action-btn:focus {
    outline: 0;
  }
  
  .music-info {
    display: none;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 15px 15px 0 0;
    position: absolute;
    top: 0;
    left: 20px;
    width: calc(100% - 40px);
    padding: 10px 10px 10px 150px;
    opacity: 0;
    transform: translateY(0%);
    transition: transform 0.3s ease-in, opacity 0.3s ease-in;
    z-index: 0;
  }
  
  .music-container.play .music-info {
    opacity: 1;
    transform: translateY(-100%);    
  }
  
  .music-info h4 {
    margin: 0;
  }
  
  .progress-container {
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px 0;
    height: 4px;
    width: 100%;
  }
  
  .progress {
    background-color: #fe8daa;
    border-radius: 5px;
    height: 100%;
    width: 0%;
    transition: width 0.1s linear;
  }

  #prev, #next {
    opacity: 0;
    pointer-events: none;
  }