.sidebar {
  /* max-width: 20%; */
  width: 100%;
  background: #f5f5f5;
  /* border-radius: 10px; */
  overflow: hidden;
  /* border: 1px solid #dedcdc; */
  margin-bottom: 20px;
  min-height: calc(100vh - 110px);
}
.navLink a {
  padding: 15px 10px;
  display: block;
  font-size: 17px;
  text-transform: capitalize;
  color: #000;
  border-bottom: 1px solid #dedcdc;
  line-height: 1.5;
  font-weight: 800;
  /* text-align: center; */
}
@media(min-width:992px){
  .sidebar {
    max-width: 15%;
    margin-bottom: 0;
  }
}
