section#menu04-1p {
    position: absolute;
    width: 100%;
    z-index: 9;
}
div#navbarSupportedContent {
    justify-content: end;
}
.hamburger span {
    height: 0.188rem;
    display: block;
    width: 2.125rem;
    background: var(--primary-color3);
    margin: 0.188rem 0;
    padding: 0;
}
.hamburger:active span{
    background: var(--primary-color9);
}
.navbar-toggler:focus{
    box-shadow: none !important;
}

nav.navbar{
    padding: 15px;
    background: transparent;
    height: var(--header);
}
.nav-link{
    color: var(--header-link) !important;
    font-family: var(--cabinFont);
}
.nav-link:hover{
    color: var( --primary-color3) !important;
}

.fixed {
    position: fixed !important;
    width: 100%;
    background: var(--primary-color1) !important;
    transition: all 0.5s ease-in-out;
}

@media(max-width:992px){
    li.nav-item {
        margin: 10px -15px;
        padding: 0 15px;
        border-bottom: 1px solid var(--primary-color2);
    }
    .navbar-buttons .createBtn{
        width: 40%;
    }
    div#navbarSupportedContent {
        margin: 0 -30px;
        padding: 30px 15px;
        background: var(--primary-color1);
        border-bottom: 2px solid var(--paragraphFontcolor);
    }
}

.profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid var(--primary-color3);
}
.wapper-profile{
    position: relative;
    cursor: pointer;
}
.profile-list {
    background: var(--primary-color2);
    width: 150px;
    /* border: 1px solid black; */
    /* text-align: center; */
    position: absolute;
    left: 7%;
    transform: translateX(-50%);
    border-radius: 7px;
    box-shadow: 2px 3px 5px gray;
    overflow: hidden;
    top: 62px;
    
}
.profile-list a {
    font-size: 16px;
    color: var(--primary-color3) !important;
    line-height: 1.5;
    display: block;
    border-bottom: 1px solid;
    padding: 5px 10px;
    transition: all 0.5s ease;
}
.profile_outer-div::before {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: -12px;
    left: 50%;
    border-width: 10px;
    transform: translateX(-50%);
    border-color: transparent transparent var(--primary-color2) transparent;
    border-style: solid;
}
.profile-list a:hover{
    background-color: var(--primary-color3);
    color: var(--primary-color2) !important;
}
.profile-list a:last-child{
    border-bottom: none;
}
.menu_items{
    position: absolute;
    top: 60px;
    background: #e7e6e6;
    width: 300px;
    right: -100px;
    padding: 10px;
    border-radius: 10px;
}
.menu_items a {
    display: block;
    padding: 5px;
    color: #303536;
    border-radius: 8px;
    transition: all 0.5s ease;
   
}
.menu_items a:hover{
    background: #bcceeb
}
ul.child_user {
    position: absolute;
    background: #e7e6e6;
    width: 0;
    right: 300px;
    overflow: hidden;
    top: 13px;
    white-space: nowrap; 
    /* display: none; */
    transition: all 0.5s ease;
}

.outer_list:hover ul.child_user {
    width: 300px;
}

.selected-child a {
    color: red; /* Change to your desired color */
    font-weight: bold; /* Optionally make it bold */
  }
  

