#features04-1r {
    padding: 7.5rem 0;
    background:var(--bg-gray-background);
}
.adventures {
  gap: 0.313rem;
}
.item.features-image {
  flex: 0 0 calc(50% - 0.313rem);
  margin-bottom: 0.313rem;
  padding: 1rem;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  box-shadow: -1px 3px 20px 0px rgb(82, 90, 101, 0.1);
  .item-wrapper {
    display: flex;
    flex-direction: column;
  }
}
.sometihingaboutstory {
  color: var(--paragraphFontcolor);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0.2px;
  font-family: var(--cabinFont);
  margin-bottom: 15px;
  text-align: left;
}
.item-img {
  height: 230px;
  transition: all 0.5s ease;
  filter: grayscale(0.5);
  margin-bottom: 15px;
}
.item.features-image:hover .item-img{
  filter: none;
}
.item.features-image:hover .item-title{
    color: var(--primary-color9);
}
.item.features-image:hover {
    box-shadow:1px 1px 8px #acabab
}
.item-img img {
  height: 100%;
  object-fit: cover;
}
.story_continue {
  text-decoration: none;
}

.item-title {
  font-size: 20px;
  margin-bottom: 15px;
  text-decoration: none;
  text-align: left;
  white-space: nowrap;
  width: 264px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--primary-color8);
}
.item-title strong {
  font-weight: 400;
}
/* .item-wrapper:hover .item-img{
    transform: scale(1.1);
} */
.single_story_wapper .points .item-content {
  position: initial;
  background: transparent;
  padding: 0;
}
.single_story_wapper .points .item.features-without-image,
.single_story_wapper .points .item.features-without-image .item-wrapper {
  height: auto;
  border-radius: 0;
}
.single_story_wapper .points .item.features-without-image {
  border-left: 0.263rem solid var(--primary-color3);
  border-right: 0.263rem solid var(--primary-color3);
  margin-bottom: 20px;
  padding: 0.238rem 0.938rem 0.238rem;
  border-radius: 10px;
  transition: all 0.5s ease;
}

.single_story_wapper .points .item.features-without-image:hover {
  border-left: 0.263rem solid var(--primary-color9);
  border-right: 0.263rem solid var(--primary-color9);
}
/* .single_story_wapper .points  .item.features-without-image:last-child{
    border: none;
} */
.single_story_wapper .story_img {
  /* clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 55% 76%, 47% 100%, 41% 76%, 0% 75%); */
  border-radius: 0.625rem;
  overflow: hidden;
  height: 21.875rem;
  margin-bottom: 1.875rem;
}
.single_story_wapper .story_img img {
  height: 100%;
}
.single_story_page {
  padding-top: 3.125rem;
}
.single_story_page h1 {
  font-size: 1.563rem;
  text-align: center;
  color: var(--primary-color2);
}
.single_story_page h1 sub {
  font-size: 1.25rem;
  color: var(--primary-color3);
  line-height: 1;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 20px;
  display: inline-block;
}
.single_story_wapper .points .item-content h5 {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--primary-color2);
}
.single_story_wapper .points .item-content p {
  font-size: 1.063rem;
  font-weight: 400;
  color: var(--primary-color1);
  text-align: left;
}
p.author,.views,.likes{
    font-size: 0.7rem;
    line-height: 1;
    color: var(--paragraphFontcolor);
    margin: 0;
}

.liked{
  font-weight: 600 !important;
  color: red;
  /* margin-right: 7px; */
}
.item-body {
    margin-bottom: 23px;
    border-bottom:1px solid rgb(216 216 216);
}
p.author strong,.views strong,.likes strong{
    margin-right: 5px;
    color: var(--primary-color4);
}

@media (min-width: 767px) {
  .adventures {
    gap: 0.513rem;
  }
  .item.features-image {
    /* flex-basis: calc(33.33% - 0.513rem); */
    margin-bottom: 0.513rem;
  }
  .single_story_wapper .story_img {
    height: 31.875rem;
    margin-bottom: 3rem;
  }
  .about_story .publish_btn {
    width: 50%;
    margin-top: 30px;
  }
  .single_story_page h1 {
    font-size: 2.563rem;
  }
  .single_story_wapper .points .item-content h5 {
    font-size: 1.05rem;
  }
  .single_story_wapper .points .item-content p {
    font-size: 0.863rem;
  }

  /* .item-wrapper {
        height: 13.675rem;
    } */
}
.about_story{
  flex: 0  0 50%;
  text-align: center;
}
div#accordionExample {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .adventures {
    gap: 0.413rem;
  }
  .item.features-image {
    flex-basis: calc(33.33% - 0.413rem);
    margin-bottom: 0.413rem;
  }

}
@media (min-width: 1199px) {
  .item.features-image {
    flex-basis: calc(25% - 0.413rem);
    margin-bottom: 0.413rem;
  }
}
