h4.more_stories {
    font-size: var(--heddingFont);
    text-align: center;
    color: var(--primary-color3);
    margin-bottom: 1.5rem;
}

.item.features-without-image {
    background: var(--primary-color3);
    border-radius: 10px;
}
.item.features-without-image{
    margin-bottom: 10px;
}
.img-wrapper {
    height: 100%;
    transition: all 0.5s ease;
    height: 240px;
}
.img-wrapper img {
    height: 100%;
    object-fit: cover;
}
.gap{
    gap: 10px;
}
.card-box {
    padding: 0.313rem 0 0;
}

.card-title {
    font-size: 12px;
    margin-bottom: 15px;
    text-decoration: none;
    text-align: left;
    white-space: nowrap;
    width: 99%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--primary-color1);
}
.card-title strong{
    font-weight: 400;
}

@media(min-width:767px){
    .item.features-without-image {
        /* flex-basis: calc(50% - 15px);
        cursor: pointer;
        max-width: 24%; */
    }
    .gap {
        gap: 9px;
    }

}
@media(min-width:1199px){
    .img-wrapper {
        height: 100%;
        transition: all 0.5s ease;
        height:330px;
    }
    .card-title {
        font-size: 17px;
    }
}
button.slick-arrow {
 display: none !important
}
