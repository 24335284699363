

#features028-1u,.banner{
    background: url(../../public/images/backgroundmobile.jpg)center no-repeat;
    background-size: cover;
    min-height: 100vh;
    padding: 5rem 0;
    position: relative;
    height: 100%;
}
#features028-1u{
    background-attachment: fixed;

}
.banner-text {
    margin-top: 5rem;
    text-align: center;
}
.banner-text h1 span{
    display: block;
    font-size: 1rem;
    line-height: 1.1rem;
    color: var(--primary-color2);
}
span.highlight {
    display: inline-block !important;
    color: var(--primary-color3) !important;
}
.banner-text h1 strong{
    display: block;
    font-size: 2rem;
    line-height: 2.3rem;
    color: var(--primary-color3);
}
.banner-text p{
    font-size: 0.9rem;
    color: var(--primary-color4);
}
.btn-close{
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 99;
}
.selectChild .childrenProfile{
    padding-top: 20px;
}
@media(min-width:576px){
    .modal-dialog {
        max-width: 70% !important;
        margin: 1.75rem auto;
        width: 100%;
    }
}
@media(min-width:767px){
    .banner-text {
        margin-top: 0rem;
    }
    .imgwapper{
        width: 73%;
        margin: 0 auto;
    }


}
@media(min-width:992px){
    .banner-text {
        margin-top: 5rem;
    }
    .banner-text h1 span {
        font-size: 2rem;
        line-height: 2.1rem;
    }
    .banner-text h1 strong {
        font-size: 4rem;
        line-height: 4.3rem;
    }
    .banner-text p {
        font-size: 1.3rem;
    }
    .createBtn, .publish_btn {
        padding: 17px 52px;
    }
}
@media(min-width:1199px){

    .banner-text{
        width: 50%;
        text-align: left;
    }
    
    .imgwapper {
        width: 38%;
        margin: 0;
    }

    .banner-text h1 span {
        font-size: 1rem;
        line-height: 1.1rem;
    }
    .banner-text h1 strong {
        font-size: 2rem;
        line-height: 2.3rem;
    }
    .banner-text p {
        font-size: 0.7rem;
        text-align: left;
    }

}
@media(min-width:1400px){
    .imgwapper {
        width: 50%;
        margin: 0;
    }
}
@media(min-width:1062px){
    .banner-text{
        width: 40%;
    }
}
