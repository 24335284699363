
.singlestory_img {
    border-radius: 0.625rem;
    overflow: hidden;
    height: 21.875rem;
    margin-bottom: 1.875rem;
}
.singlestory_img .singlestory_img img{
    height: 100%;
}
.perstory .chapter-content {
    color: var(--primary-color4);
}
.perstory .publish_btn span {
    font-size: 0.6rem;
    text-transform: capitalize;
}

.fullstory_text{
    padding: 1px;
}

.loaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.perstory .item.features-without-image {
    /* flex-basis: calc(50% - 15px);
    cursor: pointer; */
    max-width: 100% !important;
}
.perstory .card-title {
    font-size: 31px !important;
    color: var(--primary-color2) !important;
    all: unset;
}
@media(max-width:767px){
    .singlestory_img img{
      border-radius: 50%;
      border-width: 11px;
      border-style: ridge;
      border-color: var(--primary-color2);
      margin-bottom: 20px;
      animation: myAnim 2s ease 0s 1 normal forwards;
    }

  }
  
  @keyframes myAnim {
    0% {
      animation-timing-function: ease-in;
          opacity: 0;
          transform: scale(7);
      }
  
      38% {
          animation-timing-function: ease-out;
          opacity: 1;
          transform: scale(1);
      }
  
      55% {
          animation-timing-function: ease-in;
          transform: scale(1.5);
      }
    
      72% {
      animation-timing-function: ease-out;
          transform: scale(1);
      }
  
      81% {
      animation-timing-function: ease-in;
          transform: scale(1.24);
      }
    
      89% {
      animation-timing-function: ease-out;
          transform: scale(1);
      }
  
      95% {
          animation-timing-function: ease-in;
          transform: scale(1.04);
      }
  
      100% {
          animation-timing-function: ease-out;
          transform: scale(1);
      }
  }
@media(min-width:767px){
    .perstory .card-title {
        font-size: 40px !important;
    }
}
  @media(min-width:992px){
    .singlestory_img {
        /* height: 31.875rem; */
        margin-bottom: 3rem;
        flex: 0 0 50%;
    }
    .story_content {
        flex: 0 0 50%;
    }
    .perstory {
        display: flex;
        height: calc(100vh - 180px);
    }
    .perstory .card-title {
        font-size: 40px !important;
        color: var(--primary-color2) !important;
        all: unset;
    }
  }

  .perstory .item.features-without-image,.perstory .item-wrapper{
    height: auto;
  }
@media (min-width:1199px){
    .perstory .publish_btn span {
        font-size: 1rem;
    }
    .perstory .card-title {
        font-size: 55px !important;
    }
}