/* mobirise2.css */
@font-face {
    font-family: 'Moririse2';
    font-display: swap;
    /* src:  url('mobirise2.eot?f2bix4');
    src:  url('mobirise2.eot?f2bix4#iefix') format('embedded-opentype'),
      url('mobirise2.ttf?f2bix4') format('truetype'),
      url('mobirise2.woff?f2bix4') format('woff'),
      url('mobirise2.svg?f2bix4#mobirise2') format('svg'); */
    font-weight: normal;
    font-style: normal;
  }
  
  [class^="mobi-"], [class*=" mobi-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Moririse2' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .mobi-mbri-add-submenu:before {
    content: "\e900";
  }
  .mobi-mbri-alert:before {
    content: "\e901";
  }
  .mobi-mbri-align-center:before {
    content: "\e902";
  }
  .mobi-mbri-align-justify:before {
    content: "\e903";
  }
  .mobi-mbri-align-left:before {
    content: "\e904";
  }
  .mobi-mbri-align-right:before {
    content: "\e905";
  }
  .mobi-mbri-android:before {
    content: "\e906";
  }
  .mobi-mbri-apple:before {
    content: "\e907";
  }
  .mobi-mbri-arrow-down:before {
    content: "\e908";
  }
  .mobi-mbri-arrow-next:before {
    content: "\e909";
  }
  .mobi-mbri-arrow-prev:before {
    content: "\e90a";
  }
  .mobi-mbri-arrow-up:before {
    content: "\e90b";
  }
  .mobi-mbri-bold:before {
    content: "\e90c";
  }
  .mobi-mbri-bookmark:before {
    content: "\e90d";
  }
  .mobi-mbri-bootstrap:before {
    content: "\e90e";
  }
  .mobi-mbri-briefcase:before {
    content: "\e90f";
  }
  .mobi-mbri-browse:before {
    content: "\e910";
  }
  .mobi-mbri-bulleted-list:before {
    content: "\e911";
  }
  .mobi-mbri-calendar:before {
    content: "\e912";
  }
  .mobi-mbri-camera:before {
    content: "\e913";
  }
  .mobi-mbri-cart-add:before {
    content: "\e914";
  }
  .mobi-mbri-cart-full:before {
    content: "\e915";
  }
  .mobi-mbri-cash:before {
    content: "\e916";
  }
  .mobi-mbri-change-style:before {
    content: "\e917";
  }
  .mobi-mbri-chat:before {
    content: "\e918";
  }
  .mobi-mbri-clock:before {
    content: "\e919";
  }
  .mobi-mbri-close:before {
    content: "\e91a";
  }
  .mobi-mbri-cloud:before {
    content: "\e91b";
  }
  .mobi-mbri-code:before {
    content: "\e91c";
  }
  .mobi-mbri-contact-form:before {
    content: "\e91d";
  }
  .mobi-mbri-credit-card:before {
    content: "\e91e";
  }
  .mobi-mbri-cursor-click:before {
    content: "\e91f";
  }
  .mobi-mbri-cust-feedback:before {
    content: "\e920";
  }
  .mobi-mbri-database:before {
    content: "\e921";
  }
  .mobi-mbri-delivery:before {
    content: "\e922";
  }
  .mobi-mbri-desktop:before {
    content: "\e923";
  }
  .mobi-mbri-devices:before {
    content: "\e924";
  }
  .mobi-mbri-down:before {
    content: "\e925";
  }
  .mobi-mbri-download-2:before {
    content: "\e926";
  }
  .mobi-mbri-download:before {
    content: "\e927";
  }
  .mobi-mbri-drag-n-drop-2:before {
    content: "\e928";
  }
  .mobi-mbri-drag-n-drop:before {
    content: "\e929";
  }
  .mobi-mbri-edit-2:before {
    content: "\e92a";
  }
  .mobi-mbri-edit:before {
    content: "\e92b";
  }
  .mobi-mbri-error:before {
    content: "\e92c";
  }
  .mobi-mbri-extension:before {
    content: "\e92d";
  }
  .mobi-mbri-features:before {
    content: "\e92e";
  }
  .mobi-mbri-file:before {
    content: "\e92f";
  }
  .mobi-mbri-flag:before {
    content: "\e930";
  }
  .mobi-mbri-folder:before {
    content: "\e931";
  }
  .mobi-mbri-gift:before {
    content: "\e932";
  }
  .mobi-mbri-github:before {
    content: "\e933";
  }
  .mobi-mbri-globe-2:before {
    content: "\e934";
  }
  .mobi-mbri-globe:before {
    content: "\e935";
  }
  .mobi-mbri-growing-chart:before {
    content: "\e936";
  }
  .mobi-mbri-hearth:before {
    content: "\e937";
  }
  .mobi-mbri-help:before {
    content: "\e938";
  }
  .mobi-mbri-home:before {
    content: "\e939";
  }
  .mobi-mbri-hot-cup:before {
    content: "\e93a";
  }
  .mobi-mbri-idea:before {
    content: "\e93b";
  }
  .mobi-mbri-image-gallery:before {
    content: "\e93c";
  }
  .mobi-mbri-image-slider:before {
    content: "\e93d";
  }
  .mobi-mbri-info:before {
    content: "\e93e";
  }
  .mobi-mbri-italic:before {
    content: "\e93f";
  }
  .mobi-mbri-key:before {
    content: "\e940";
  }
  .mobi-mbri-laptop:before {
    content: "\e941";
  }
  .mobi-mbri-layers:before {
    content: "\e942";
  }
  .mobi-mbri-left-right:before {
    content: "\e943";
  }
  .mobi-mbri-left:before {
    content: "\e944";
  }
  .mobi-mbri-letter:before {
    content: "\e945";
  }
  .mobi-mbri-like:before {
    content: "\e946";
  }
  .mobi-mbri-link:before {
    content: "\e947";
  }
  .mobi-mbri-lock:before {
    content: "\e948";
  }
  .mobi-mbri-login:before {
    content: "\e949";
  }
  .mobi-mbri-logout:before {
    content: "\e94a";
  }
  .mobi-mbri-magic-stick:before {
    content: "\e94b";
  }
  .mobi-mbri-map-pin:before {
    content: "\e94c";
  }
  .mobi-mbri-menu:before {
    content: "\e94d";
  }
  .mobi-mbri-mobile-2:before {
    content: "\e94e";
  }
  .mobi-mbri-mobile-horizontal:before {
    content: "\e94f";
  }
  .mobi-mbri-mobile:before {
    content: "\e950";
  }
  .mobi-mbri-mobirise:before {
    content: "\e951";
  }
  .mobi-mbri-more-horizontal:before {
    content: "\e952";
  }
  .mobi-mbri-more-vertical:before {
    content: "\e953";
  }
  .mobi-mbri-music:before {
    content: "\e954";
  }
  .mobi-mbri-new-file:before {
    content: "\e955";
  }
  .mobi-mbri-numbered-list:before {
    content: "\e956";
  }
  .mobi-mbri-opened-folder:before {
    content: "\e957";
  }
  .mobi-mbri-pages:before {
    content: "\e958";
  }
  .mobi-mbri-paper-plane:before {
    content: "\e959";
  }
  .mobi-mbri-paperclip:before {
    content: "\e95a";
  }
  .mobi-mbri-phone:before {
    content: "\e95b";
  }
  .mobi-mbri-photo:before {
    content: "\e95c";
  }
  .mobi-mbri-photos:before {
    content: "\e95d";
  }
  .mobi-mbri-pin:before {
    content: "\e95e";
  }
  .mobi-mbri-play:before {
    content: "\e95f";
  }
  .mobi-mbri-plus:before {
    content: "\e960";
  }
  .mobi-mbri-preview:before {
    content: "\e961";
  }
  .mobi-mbri-print:before {
    content: "\e962";
  }
  .mobi-mbri-protect:before {
    content: "\e963";
  }
  .mobi-mbri-question:before {
    content: "\e964";
  }
  .mobi-mbri-quote-left:before {
    content: "\e965";
  }
  .mobi-mbri-quote-right:before {
    content: "\e966";
  }
  .mobi-mbri-redo:before {
    content: "\e967";
  }
  .mobi-mbri-refresh:before {
    content: "\e968";
  }
  .mobi-mbri-responsive-2:before {
    content: "\e969";
  }
  .mobi-mbri-responsive:before {
    content: "\e96a";
  }
  .mobi-mbri-right:before {
    content: "\e96b";
  }
  .mobi-mbri-rocket:before {
    content: "\e96c";
  }
  .mobi-mbri-sad-face:before {
    content: "\e96d";
  }
  .mobi-mbri-sale:before {
    content: "\e96e";
  }
  .mobi-mbri-save:before {
    content: "\e96f";
  }
  .mobi-mbri-search:before {
    content: "\e970";
  }
  .mobi-mbri-setting-2:before {
    content: "\e971";
  }
  .mobi-mbri-setting-3:before {
    content: "\e972";
  }
  .mobi-mbri-setting:before {
    content: "\e973";
  }
  .mobi-mbri-share:before {
    content: "\e974";
  }
  .mobi-mbri-shopping-bag:before {
    content: "\e975";
  }
  .mobi-mbri-shopping-basket:before {
    content: "\e976";
  }
  .mobi-mbri-shopping-cart:before {
    content: "\e977";
  }
  .mobi-mbri-sites:before {
    content: "\e978";
  }
  .mobi-mbri-smile-face:before {
    content: "\e979";
  }
  .mobi-mbri-speed:before {
    content: "\e97a";
  }
  .mobi-mbri-star:before {
    content: "\e97b";
  }
  .mobi-mbri-success:before {
    content: "\e97c";
  }
  .mobi-mbri-sun:before {
    content: "\e97d";
  }
  .mobi-mbri-sun2:before {
    content: "\e97e";
  }
  .mobi-mbri-tablet-vertical:before {
    content: "\e97f";
  }
  .mobi-mbri-tablet:before {
    content: "\e980";
  }
  .mobi-mbri-target:before {
    content: "\e981";
  }
  .mobi-mbri-timer:before {
    content: "\e982";
  }
  .mobi-mbri-to-ftp:before {
    content: "\e983";
  }
  .mobi-mbri-to-local-drive:before {
    content: "\e984";
  }
  .mobi-mbri-touch-swipe:before {
    content: "\e985";
  }
  .mobi-mbri-touch:before {
    content: "\e986";
  }
  .mobi-mbri-trash:before {
    content: "\e987";
  }
  .mobi-mbri-underline:before {
    content: "\e988";
  }
  .mobi-mbri-undo:before {
    content: "\e989";
  }
  .mobi-mbri-unlink:before {
    content: "\e98a";
  }
  .mobi-mbri-unlock:before {
    content: "\e98b";
  }
  .mobi-mbri-up-down:before {
    content: "\e98c";
  }
  .mobi-mbri-up:before {
    content: "\e98d";
  }
  .mobi-mbri-update:before {
    content: "\e98e";
  }
  .mobi-mbri-upload-2:before {
    content: "\e98f";
  }
  .mobi-mbri-upload:before {
    content: "\e990";
  }
  .mobi-mbri-user-2:before {
    content: "\e991";
  }
  .mobi-mbri-user:before {
    content: "\e992";
  }
  .mobi-mbri-users:before {
    content: "\e993";
  }
  .mobi-mbri-video-play:before {
    content: "\e994";
  }
  .mobi-mbri-video:before {
    content: "\e995";
  }
  .mobi-mbri-watch:before {
    content: "\e996";
  }
  .mobi-mbri-website-theme-2:before {
    content: "\e997";
  }
  .mobi-mbri-website-theme:before {
    content: "\e998";
  }
  .mobi-mbri-wifi:before {
    content: "\e999";
  }
  .mobi-mbri-windows:before {
    content: "\e99a";
  }
  .mobi-mbri-zoom-in:before {
    content: "\e99b";
  }
  .mobi-mbri-zoom-out:before {
    content: "\e99c";
  }




  