.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow-x: auto;
}

.table  {
    width: 90% !important;
    border-collapse: collapse;
}

.table thead {
    --bs-table-bg: #95c535 !important;
    background: #95c535 !important;
    background-color: #95c535 !important;
}

.table th {
    color: #FFF !important;
    padding: 5px 5px;
    font-size: 14px;
    border: 1px solid #757575;
    text-align: center;
}
.table td {
    padding: 5px 5px;
    font-size: medium;
    border: 1px solid #757575;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .table thead {
        display: none;
    }

    .table tr {
        display: block;
        margin-bottom: 10px;
        align-content: end;

    }

    .table td {
        display: block;
        text-align: right;
        border-bottom: 1px solid #ddd;
        position: relative;
        padding-left: 50%;
    }

    .table td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 10px;
        font-weight: bold;
        text-align: left;
    }
    .tablecustom {
        display: flow-root;
    }

    .moveButtonRight {
        float: right;
    }

}

.storiesPage {
    padding-top: 100px;
    min-height: 40vh;
    height: 100%;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.stories {
    margin: 0 auto;
    text-align: center;
  }

  .empty-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 30vh; 
}

.empty-state p {
    font-size: 20px; /* Increased font size */
    margin-bottom: 20px;
    color: #333; /* Optional: Darker text color */
}

.empty-state .createBtn:hover {
    background-color: #0056b3;
}

