.porfile {
  padding-top: 100px;
}
.profileSettings figure {
  width: 100px;
  height: 100px;
  margin-bottom: 0;
}
.ProfileInfo{
  margin-left: 15px;
}
.ProfileInfo h4 {
  font-size: 20px;
  line-height: 1;
  color: red;
  margin-bottom: 0;
  text-transform: capitalize;
}
.ProfileInfo strong {
  font-size: 15px;
  text-transform: capitalize;
  line-height: 1;
}
.profileUpdateBtn .createBtn span{
  font-size: 18px;
  text-transform: capitalize;
}
.uploadProfile{
  margin-bottom: 30px;
}
.changeYourAccountDetails {
  /* width: 70%; */
  /* border: 1px solid #ddd; */
  border-radius: 19px;
  padding: 10px 20px;
  /* background: lavender; */
  margin: 0 auto;
}
.changeYourAccountDetails h3{
  font-size: 25px;
  line-height: 1;
}
.lastseen {
  font-size: 12px;
  line-height: 1;
  color: gray;
}

/* .childrenProfile{
  padding-top: 100px;
} */

.child_profile{
  width: 50px;
}
table.table.table-bordered.table-hover td ,table.table.table-bordered.table-hover th{
  vertical-align: middle;
  text-align: center;
}
.edit_btn,.delete_btn{
all: unset;
}
.Sidemenu{
max-width: 20%;
}
/* .custom{
  width: 80%;
} */
.active,.navLink a:hover{
  background-color: var(--primary-color2);
  color: #fff !important;
  transition: all 0.5s ease-in-out;
}
.verify{
  color: green;
  margin-left: 5px;
}
.notverify{
  /* border: 1px solid; */
  height: 20px;
  width: 20px;
  text-align: center;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  background: red;
  color: #fff;
  margin-left: 5px;
}
textarea{
  width: 100%;
min-width: 100%;
}
.updatedatabtn span{
  font-size: 1rem !important;
  width: 100px;

}
@media(min-width:992px){
  .custom {
    width: 80%;
}
}
.child-Profile{
  width: 40px;
  margin: 0 auto;
}
.porfile{
  background: url(../../../public/images/signup.png)center no-repeat;
  background-size: cover;
  min-height: 100vh;
  height: 100%;
}