#toasts {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.toast {
    background-color: #fff;
    border-radius: 5px;
    padding: 1rem 2rem;
    margin: 0.5rem;
}

.toast.info {
    color: rebeccapurple;    
    opacity: 1 !important;
}

.toast.success {
    color: green;
    opacity: 1 !important;
}

.toast.error {
    color: red;
    opacity: 1 !important;
}