.login_wapper_outer{
    height: calc(100vh);
}
.loginOuterDiv .customHeight .overflow{
    height: calc(100vh - 360px) !important;
    overflow-x: hidden;
    /* background-color: red; */
    border: none;
}

.table-responsive {
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}
.widget-26-job-emp-img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
}
.widget-26-job-title a {
    text-wrap: nowrap;
    max-width: 138px;
    display: block;
    overflow: hidden;
    font-size: 15px;
    text-overflow: ellipsis;
    text-decoration: none;
    color: var(--primary-color2);
}
.widget-26-job-info p.type.m-0 {
    font-size: 14px;
    text-wrap: nowrap;
    width: 87px;
    text-overflow: ellipsis;
    padding-right: 10px;
    overflow: hidden;
    font-weight: 500;
}
.widget-26-job-info {
    text-align: right;
}
.result-body table td{
    vertical-align: middle;
}
.widget-26-job-emp-img img{
    height: 100%;
    object-fit: cover;
}
.loginOuterDiv header {
    font-size: 37px;
    text-align: center;
    padding-top: 8rem;
    color: var(--primary-color10);
    font-weight: bold;
}
.customHeight {
    height: calc(100vh - 361px);
    padding: 0;
    width: 100%;
    /* border: 1px solid rebeccapurple; */
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 1px 1px 10px #8d8d8d;
}
@media(min-width:768px){
    .customHeight {
        height: calc(100vh - 361px);
        padding: 0;
        width: 50%;
    }
    .card-body {
        padding: 0.6rem 0;
    }
    .widget-26-job-emp-img {
        width: 70px;
        height: 70px;
        border-radius: 100%;
        overflow: hidden;
    }
    .widget-26-job-title a {
        max-width: 190px;
        font-size: 19px;
    }
    .widget-26-job-info p.type.m-0 {
        font-size: 19px;
        width: 170px;
    }
}

@media(min-width:992px){
    .widget-26-job-title a {
        max-width: 220px;
        font-size: 22px;
    }
    .widget-26-job-info p.type.m-0 {
        font-size: 22px;
        width: 340px;
        text-align: right;
    }
}

@media(min-width:1199px){
    .widget-26-job-title a {
        max-width: 400px;
        font-size: 22px;
    }
    .widget-26-job-info p.type.m-0 {
        font-size: 22px;
        width: 400px;
        text-align: right;
    }
}