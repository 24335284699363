@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap');
.page {
  margin-bottom: 20px;
}

h1.chapterTitle {
  margin-top: 30px;
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
  color: var(--primary-color2);
}

.page img{
  height: 100%;
}
.overflow{
  height: auto !important;
}
.story_img {
  margin-bottom: 20px;
}
.creatorName {
  font-size: 1.25rem;
  color: var(--primary-color3);
  line-height: 1;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 20px;
  text-align: center;
}
.story_images,.flipbook_cover_img {
  height: 500px;
  overflow: hidden;
}
h4.pageNo {
  display: none;
}
@media(max-width:767px){
  .story_img img{
    border-radius: 50%;
    border-width: 11px;
    border-style: ridge;
    border-color: var(--primary-color2);
    margin-bottom: 20px;
    animation: myAnim 2s ease 0s 1 normal forwards;
  }
}

@keyframes myAnim {
  0% {
    animation-timing-function: ease-in;
		opacity: 0;
		transform: scale(7);
	}

	38% {
		animation-timing-function: ease-out;
		opacity: 1;
		transform: scale(1);
	}

	55% {
		animation-timing-function: ease-in;
		transform: scale(1.5);
	}
  
	72% {
    animation-timing-function: ease-out;
		transform: scale(1);
	}

	81% {
    animation-timing-function: ease-in;
		transform: scale(1.24);
	}
  
	89% {
    animation-timing-function: ease-out;
		transform: scale(1);
	}

	95% {
		animation-timing-function: ease-in;
		transform: scale(1.04);
	}

	100% {
		animation-timing-function: ease-out;
		transform: scale(1);
	}
}
h1.chapter_title {
  text-align: center;
  font-size: 46px;
  line-height: 50px;
  color: var(--primary-color2);
  font-family: var(--bookHeadFont);
  margin-bottom: 20px;
  position: relative;
}
h1.chapter_title::before {
  height: 4px;
  width: 30%;
  background: var(--primary-color2);
  position: absolute;
  bottom: -6px;
  left: 50%;
  content: "";
  transform: translateX(-50%);
  border-radius: 10px;
}
@media(min-width:767px){
  h1.chapterTitle {
    font-size: 50px;
  }
}
@media(max-width:1199px){
  .audio {
    border-radius: 26px;
    background: #092635;
    box-shadow: inset 5px 5px 10px #061822,
    inset -5px -5px 10px #0c3448;
    padding: 16px;
  }
}
  @media(min-width:1199px){
  
  
  
    .book.container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100vh - var(--header));
  }
  .book {
      transition: opacity 0.4s 0.2s;
    }
    /* .book  p{
      margin-top: 8vw;
      text-align: center;
      font-size: 5vw;
      color: #000000;
    } */
    .page {
      width: 30vw;
      height: 44vw;
      background-color: #111111;
      float: left;
      margin-bottom: 0.5em;
      background: left top no-repeat;
      background-size: cover;
    }
    .page:nth-child(even) {
      clear: both;
    }
    .book {
      perspective: 250vw;
      height: calc(100vh - var(--header));
  }
    .book .pages {
      width: 70vw;
      /* height: 40vw; */
      height: 90%;
      position: relative;
      transform-style: preserve-3d;
      backface-visibility: hidden;
      border-radius: 4px;
      /*box-shadow: 0 0 0 1px #e3dfd8;*/
    }
    .book .page {
      float: none;
      clear: none;
      margin: 0;
      position: absolute;
      top: 0;
      width: 35vw;
      /* height: 40vw; */
      height: 100%;
      transform-origin: 0 0;
      transition: transform 1.4s;
      backface-visibility: hidden;
      transform-style: preserve-3d;
      cursor: pointer;
      user-select: none;
      background-color: #f0f0f0;
    }
    /* .book .page:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0);
      transition: background 0.7s;
      z-index: 2;
    } */
    .book .page:nth-child(odd) {
      pointer-events: all;
      transform: rotateY(0deg);
      right: 0;
      border-radius: 0 4px 4px 0;
      background-image: linear-gradient(to right, rgba(0,0,0,.15) 0%, rgba(0,0,0,0) 10%);
    }
    /* .book .page:nth-child(odd):hover {
      transform: rotateY(-15deg);
    } */
    .book .page:nth-child(odd):hover:before {
      background: rgba(0, 0, 0, 0.03);
    }
    /* .book .page:nth-child(odd):before {
      background: rgba(0, 0, 0, 0);
    } */
    .book .page:nth-child(even) {
      pointer-events: none;
      transform: rotateY(180deg);
      transform-origin: 100% 0;
      left: 1px;
      border-radius: 4px 0 0 4px;
      border-color: black;
      background-image: linear-gradient(to left, rgba(0,0,0,.12) 0%, rgba(0,0,0,0) 10%);
    }
    .book .page:nth-child(even):before {
      background: rgba(0, 0, 0, 0.2);
    }
    .book .page.grabbing {
      transition: none;
    }
    .book .page.flipped:nth-child(odd) {
      pointer-events: none;
      transform: rotateY(-180deg);
    }
    .book .page.flipped:nth-child(odd):before {
      background: rgba(0, 0, 0, 0.2);
    }
    .book .page.flipped:nth-child(even) {
      pointer-events: all;
      transform: rotateY(0deg);
    }
    .book .page.flipped:nth-child(even):hover {
      transform: rotateY(15deg);
    }
    .book .page.flipped:nth-child(even):hover:before {
      background: rgba(0, 0, 0, 0.03);
    }
    .book .page.flipped:nth-child(even):before {
      background: rgba(0, 0, 0, 0);
    }
    *,
    * :before,
    *:after {
      box-sizing: border-box;
    }
    html,
    body {
      font-family: 'Lovers Quarrel', cursive;
      /* background: #333; */
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    /* html {
      height: 100%;
    }
    body {
      min-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1.5em;
    } */
    .page:nth-child(odd){
      background-position: right top;
    }
  
  
  
    h1.chapterTitle {
      position: absolute;
      bottom: 60px;
      color: #fff;
      left: 0;
      text-shadow: 
       2px   0  0   #000, 
      -2px   0  0   #000, 
       0    2px 0   #000, 
       0   -2px 0   #000, 
       1px  1px 0   #000, 
      -1px -1px 0   #000, 
       1px -1px 0   #000, 
      -1px  1px 0   #000,
       1px  1px 5px #000;
       font-size: 40px;
  }
  .flipbook_cover_img,.flipbook_cover_img .story_img,.page >div{
      width: 100%;
      height: 100%;
    }
    .flipbook_cover_img .story_img img{
      opacity: 1 !important;
    }
    .flipbook_cover_img img,.page>div img{
      width: 100%;
      height: 100%;
      opacity: 0.7 !important;
  }
  .page{
      padding: 20px;
  }
.overflow {
    height: calc(100% - 110px) !important;
    overflow-y: auto;
}
  .overflow::-webkit-scrollbar {
      width: 5px;
    }
     
    .overflow::-webkit-scrollbar-thumb {
      background: #9AD0C2;
      border-radius: 20px;
    }
    
    .overflow::-webkit-scrollbar-track {
      background: #2D9596;
      border-radius: 20px;
    }
    
  
  .flipbook_cover_img .story_img h3{
      position: absolute;
      bottom: 18px;
      left: 34px;
      color: #fff;
      text-transform: capitalize;
      font-size: 20px;
      text-shadow: 
       2px   0  0   #000, 
      -2px   0  0   #000, 
       0    2px 0   #000, 
       0   -2px 0   #000, 
       1px  1px 0   #000, 
      -1px -1px 0   #000, 
       1px -1px 0   #000, 
      -1px  1px 0   #000,
       1px  1px 5px #000;
  }
  .story_img h3{
    bottom: 18px;
    left: 24px;
  }
  h4.pageNo {
      position: absolute;
      bottom: 10px;
      width: 100%;
      text-align: center;
      font-size: 20px;
      color: #848a2c;
  }
  
  
  .chapter_title {
      font-size: 40px;
      text-align: center;
      width: 70%;
      margin: 0 auto;
      /* margin-top: 40px; */
      margin-bottom: 10px;
      font-family: "Tangerine", cursive;
      font-weight: 700;
  }
  
  .page{
    background: url(..//..//public/images/paper.png) top left no-repeat !important;
    background-size: cover !important;
  }
  }

  @media(min-width:1400px){
    h1.chapterTitle {
      font-size: 30px;
      padding: 0 25px;
  }
  }