.add-child-profile-form {
  display: flex;
  flex-direction: column;
}

.add-child-profile-form h4 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 40px;
}

.form-row {
  display: flex;
  justify-content: space-between; /* Align items in a row */
  margin-bottom: 10px;  /* Adds margin between rows */
}

.form-field {
  display: flex;
  flex-direction: row;
  align-items: center;  /* Vertically aligns the input and label */
  margin-bottom: 10px;  /* Adds space between label and input fields */
}

.form-field label {
  width: 150px; /* Adjust width to control space between label and input */
  margin-right: 10px; /* Adds space between label and input */
}

.form-field input,
.form-field select,
.form-field textarea {
  flex: 1;  /* Allows inputs to take up available space */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.upload-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 50px;
}

.resize-image {
  width: 80px;
  height: 80px;
  margin-bottom: 10px; /* Adds space below the image */
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


.custom-input {
  width: 280px;
}
