@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap');



/* font family */

/* font-family: "Cabin", sans-serif;;
font-family: "Sue Ellen Francisco", cursive; */

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
}
:root {
    --cabinFont:"Cabin", sans-serif;
    --bookHeadFont:"Italianno", cursive;
    --bookFont: "Sue Ellen Francisco", cursive;
    --primary-color1: rgba(255, 255, 255, 1);
    --primary-color2: #95c535;
    --primary-color3: rgb(123, 104, 238);
    --header-link:rgb(85, 85, 85);
    --primary-color9: rgb(62, 87, 208);
    --primary-color8: #404040;
    --primary-color4:#333;
    --bg-gray-background:#f6f5ff;
    --paragraphFontcolor:#5f5f5f;
    --container-width: 87.5rem;
    --remReset: 62.5%;
    --header:4.375rem;
    --heddingFont:2.5rem;
    --section_bottom_margin:5rem;

}
html{
    font-size: 16px;
}
body{
    font-family: var(--cabinFont);
}
.navbar-brand{
    width: 100px;
}
img{
    width: 100%;
}
.container {
    /* max-width: var(--container-width); */
    max-width:1400px;
    width: 100%;
    padding: 0 0.938rem;
}

.createBtn, .publish_btn {
    position: relative;
    z-index: 1;
    color: rgb(255, 255, 255);
    text-align: center;
    box-shadow: rgba(123, 104, 238, 0.25) 0px 7px 25px;
    background-color: rgb(123, 104, 238);
    overflow: hidden;
    border-radius: 30px;
    transition: all 0.4s ease 0s;
    padding: 10px 20px;
    border: none;
}
.publish_btn{
    width: 100%;
    margin-bottom: 30px;
}
.createBtn::before,.publish_btn::before{
content: "";
position: absolute;
top: 0;
right: 100%;
background-color: var(--primary-color9);
height: 100%;
width: 100%;
transition: all 0.5s ease;

}

   
   .banner-text .createBtn:hover:before {
    width: 100%;
   }
   
   
.createBtn:hover::before,.publish_btn:hover::before{
    right: 0;
}
.createBtn span,.publish_btn span{
    display: block;
    position: relative;
    z-index: 2;
    font-size: 0.7rem;
    color: #fff;
}
.publish_btn span {
    font-size: 1.2rem;
    text-transform: capitalize;
}

section#features015-1s {
    padding: 7.5rem 0;
}
.item.features-without-image {
    padding: 10px;
}
.slick-initialized .slick-slide {
    display: block;
    padding: 10px;
}
@media(min-width:767px){
   
    html{
        font-size: 25px;
    }
}
@media(min-width:992px){
    html{
        font-size: 20px;
    }
}
@media(min-width:1199px){
    /* html{
        font-size: 25px;
    } */
    .custom-shape-divider-bottom-1708923343 svg {
        width: calc(106% + 0.081rem);
        height: 7.963rem;
    }
}
@media(max-width:700px){
    .navbar-brand {
        padding: 0;
        margin-top: -10px;
    }
}
.select-dropdown {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    background: transparent;
    width: 100%;
    height: 50px;
    border: 1px solid var(--primary-color5);
    background: var(--primary-color2);
    border-radius: 10px;
    padding: 0 10px;
    font-size: 20px;
    /* color: var(--primary-color3); */
    margin-bottom: 12px;
}

section#article08-1v {
    padding-top: 6rem;
}
/* ++++++++++++++++++ accordion style here =+++++++++++ */

.accordion-header {
    margin: 0;
}
button.accordion-button {
background-color: var(--primary-color3) !important;
color: var(--primary-color1) !important;
}
.accordion-button:not(.collapsed)::after {
    background-image: url("../../public/images/minus1.png");
}
.accordion-button::after {
    background-image: url("../../public/images/plus1.png");
    width: 16px;
    height: 16px;
    background-size: contain;
}
.accordion-button:focus {
    border-color: none;
    box-shadow:none;
}
.accordion-body{
    font-size: 16px;
    text-align: justify;
}
.accordion-body{
    cursor: pointer;
}


body::-webkit-scrollbar {
    width: 6px;
  }
   
  body::-webkit-scrollbar-thumb {
    background: var(--primary-color2);
    border-radius: 20px;
  }
  
  body::-webkit-scrollbar-track {
    background: var(--primary-color3);
    border-radius: 20px;
  }
  

  ::selection{
    color: var(--primary-color2);
    background-color: var(--primary-color9);
  }
  .slick-track{
    display: flex;
    margin-left: 0;
  }
